import { create } from 'zustand'

const useUserStore = create((set) => ({
  userInfo: null,
  loading: false,
  markAsReadNotification: false,
  
  setUserInfo: (info) => set({ userInfo: info }),
  setLoading: (isLoading) => set({ loading: isLoading }),
  setMarkAsReadNotification: (value) => set({ markAsReadNotification: value }),
}));

export default useUserStore;
