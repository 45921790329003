import React, { useEffect, useMemo, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsMap from 'highcharts/modules/map';
import CustomTimeline from '../../shared/CustomTimeline';
import PageHeader from '../../shared/PageHeader';
import StatusUpdateDialog from '../../shared/StatusUpdateDialog';
import TopWeather from '../../shared/TopWeather';
import { StatusSection } from '../../shared/StatusSection';
import httpClient from '../../core/api';
import useUserStore from '../../../store/UserStore';
import { Toast } from 'primereact/toast';
import Icon from '../../shared/Icon';    
// import highchartsMapData from 'highcharts/modules/mapdata/custom/us-all';
HighchartsMap(Highcharts);

const USMap = () => {
  const setLoading=useUserStore((state)=>state.setLoading);
  const toast = useRef(null);
  const [events, setEvents] = useState([]);
  const [showTimeline, setShowTimeline] = useState(false);
  const [value, setValue] = useState('');
  const [locationById, setLocationById] = useState(null);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [chartInstance, setChartInstance] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [initialView, setInitialView] = useState({ center: [0, 0], zoom: 0 });
  const [zoomedViewState, setZoomedViewState] = useState({
    center: initialView.center,
    zoom: initialView.zoom,
  });
  const [zoomOutVisible, setZoomOutVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isAgentLegendChecked, setIsAgentLegendChecked] = useState(true);
  const [isStationLegendChecked, setIsStationLegendChecked] = useState(true);
  const [isHubLegendChecked, setIsHubLegendChecked] = useState(true)
  const [selectedColumn, setSelectedColumn] = useState("Open");
  const [activeColumn, setActiveColumn] = useState("Open");
  const [chartHeight, setChartHeight] = useState(395);
  const [chartWidth, setChartWidth] = useState(window.innerWidth-100); 
  const [locations, setLocations] = useState({
    Closed: { count: 0, locations: [] },
    Limited: { count: 0, locations: [] },
    Missing: { count: 0, locations: [] },
    Open: { count: 0, locations: [] },
    All: { count: 0, locations: [] },
  });
  const [laststatusid,setLastStatusid]=useState();
  const StatusLocations = async () => {
    try {
      const res = await httpClient?.get(`/api/location-statuses`);
      if (res?.status === 200) {
        setDialogVisible(true);
        const data = res?.data;
        const updatedLocations = {
          Closed: data.Closed || { count: 0, locations: [] },
          Limited: data.Limited || { count: 0, locations: [] },
          Missing: data.Missing || { count: 0, locations: [] },
          Open: data.Open || { count: 0, locations: [] },
          Total: data?.Total,
        };
        const addCategoryToLocations = (category, locations) => {
          return locations.map((loc) => ({ ...loc, category }));
        };
        const closedLocations = addCategoryToLocations('Closed', updatedLocations.Closed.locations);
        const limitedLocations = addCategoryToLocations('Limited', updatedLocations.Limited.locations);
        const missingLocations = addCategoryToLocations('Missing', updatedLocations.Missing.locations);
        const openLocations = addCategoryToLocations('Open', updatedLocations.Open.locations);

        const allLocations = [
          ...closedLocations,
          ...limitedLocations,
          ...missingLocations,
          ...openLocations,
        ];

        updatedLocations.All = {
          count: allLocations.length,
          locations: allLocations,
        };
        setLocations(updatedLocations);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteredLocations = locations[selectedColumn]?.locations || [];
  useEffect(() => {
    const updateChartHeight = () => {
      const height = window.innerHeight - 200;
      // const height =   window.innerHeight - 165; 
      setChartHeight(height);
    };
    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => {
      window.removeEventListener('resize', updateChartHeight);
    };
  }, []);
  const dataPoints = useMemo(() => { return filteredLocations?.map(loc => {
    return {
      name: loc?.name,
      lat: parseFloat(loc?.latitude), 
      lon: parseFloat(loc?.longitude),
      id: loc?.id,
      dataLabels: {
        enabled: true,
        useHTML: true,
        align: 'center',
        verticalAlign: 'middle',
        allowOverlap: true,
        crop: false, 
        overflow: 'none', 
        y: 10,
        x:-3,
        formatter: function () {
          
          if (selectedColumn === 'All') {
         
            
            if(loc?.category==="Open"){
              if(loc?.type==="Agent" && isAgentLegendChecked){
                return `
                <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;" class="map-data-label fade-in">
                  <div style="width: 30px; height: 30px;  border-radius: 6px; display: flex; justify-content: center; align-items: center; position: relative; background-color: #107E5F;">
        <span style="border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; position: relative;">
       <div style="height:21px; border:1px solid #107E5F;"></div>
       <div style="height:8px; border-radius: 50px; width: 8px; background-color: #107E5F;"></div>
    </div>
                  <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#107E5F;">${loc?.name}</p>
                </div>`
              }
              else if(loc?.type==="Station" && isStationLegendChecked){
                return `
                <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
    
<div style=" position: relative; ">
    <div style="position: relative;">
      <div class="trie " style="    width: 31px;
      height: 31px;
      background: #107E5F;
      border-radius: 50px;"></div>
      <div class="tri " style="
        position: absolute;
        top: 18px;
  transform: rotate(180deg);
  left: 3px;
        background: #107E5F;
  width: 25px;
  aspect-ratio: 1 / cos(30deg);
  -webkit-mask: 
      conic-gradient(from -30deg at 50% calc(200% - 6px), #000 60deg, #0000 0) 
      0 100% / 100% calc(100% - 6px) no-repeat, 
      radial-gradient(4px at 50% calc(8px), #000 98%, #0000 101%), 
      radial-gradient(4px, #000 98%, #0000 101%) space no-repeat 
      0 100% / calc(2 * tan(60deg) * 4px) calc(8px);
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
    "></div>
  </div>
        <span style="transform: translate(50%, 50%);
      top: -27%;
  left: -27%;; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
    </div>
    <div style="display: flex; margin-top: px; flex-direction: column; align-items: center; position: relative;">
       <div style="height:21px; border:1px solid #107E5F;"></div>
       <div style="height:8px; border-radius: 50px; width: 8px; background-color: #107E5F;"></div>
    </div>
        <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#107E5F;">${loc?.name}</p>
</div>`
              }
              else if(loc?.type==="Hub" && isHubLegendChecked){
                return `<div class="open-Hub fade-in" style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;" class="map-data-label">
                <div style=" position: relative; ">
    <div style="
  position: relative;">
      <div class="tri a"></div>
<div class="tri alt b"></div>
<div class="tri alt c"></div>
<div class="tri alt d"></div>
<div class="tri alt e"></div>
  </div>
        <span style="transform: translate(50%, 50%);
  top: 19%;
  left: -57%; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
    </div>
    <div style="display: flex; margin-top: 22px; flex-direction: column; align-items: center; position: relative;">
       <div style="height:21px; border:1px solid #107E5F;"></div>
       <div style="height:8px; border-radius: 50px; width: 8px; background-color: #107E5F;"></div>
    </div>
                <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#107E5F;">${loc?.name}</p>
            </div>
            
            `
              }
            
            }
          else  if(loc?.category==="Limited"){
            if(loc?.type==="Agent" && isAgentLegendChecked){
              return `
              <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
                 <div style="width: 30px; height: 30px;  border-radius: 6px; display: flex; justify-content: center; align-items: center; position: relative; background-color: #238EF1;">
      <span style="border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
  </div>
  <div style="display: flex; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #238EF1;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #238EF1;"></div>
  </div>
                <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#238EF1;">${loc?.name}</p>
              </div>`
            }
            else if(loc?.type==="Station" && isStationLegendChecked){
              return `
              <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;" class="map-data-label fade-in">
  <div style=" position: relative; ">
  <div style="position: relative;">
    <div class="trie " style="    width: 31px;
    height: 31px;
    background: #238EF1;
    border-radius: 50px;"></div>
    <div class="tri " style="
      position: absolute;
      top: 18px;
transform: rotate(180deg);
left: 3px;
      background: #238EF1;
width: 25px;
aspect-ratio: 1 / cos(30deg);
-webkit-mask: 
    conic-gradient(from -30deg at 50% calc(200% - 6px), #000 60deg, #0000 0) 
    0 100% / 100% calc(100% - 6px) no-repeat, 
    radial-gradient(4px at 50% calc(8px), #000 98%, #0000 101%), 
    radial-gradient(4px, #000 98%, #0000 101%) space no-repeat 
    0 100% / calc(2 * tan(60deg) * 4px) calc(8px);
clip-path: polygon(50% 0, 100% 100%, 0 100%);
  "></div>
</div>
      <span style="transform: translate(50%, 50%);
    top: -27%;
left: -27%;; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
  </div>
  <div style="display: flex; margin-top: px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #238EF1;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #238EF1;"></div>
  </div>
      <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#238EF1;">${loc?.name}</p>
</div>`
            }
            else if(loc?.type==="Hub" && isHubLegendChecked){
              return `<div class="limited-hub fade-in" style="display: flex;  position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label">
             <div style=" position: relative; ">
  <div style="
position: relative;">
    <div class="tri a"></div>
<div class="tri alt b"></div>
<div class="tri alt c"></div>
<div class="tri alt d"></div>
<div class="tri alt e"></div>
</div>
      <span style="transform: translate(50%, 50%);
top: 19%;
left: -57%; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
  </div>
  <div style="display: flex; margin-top: 22px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #238EF1;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #238EF1;"></div>
  </div>
              <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#238EF1;">${loc?.name}</p>
          </div>
          
          `
            } 
          }
          else  if(loc?.category==="Closed"){
            if(loc?.type==="Agent" && isAgentLegendChecked){
              return `
              <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
                  <div style="width: 30px; height: 30px;  border-radius: 6px; display: flex; justify-content: center; align-items: center; position: relative; background-color: #FF2626;">
      <span style="border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%);"><img src="/icons/close.png"width="10px" /></span>
  </div>
  <div style="display: flex; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #FF2626;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #FF2626;"></div>
  </div>
                <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#FF2626;">${loc?.name}</p>
              </div>`
            }
            else if(loc?.type==="Station" && isStationLegendChecked){
              return `
              <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
 <div style=" position: relative; ">
  <div style="position: relative;">
    <div class="trie " style="    width: 31px;
    height: 31px;
    background: #FF2626;
    border-radius: 50px;"></div>
    <div class="tri " style="
      position: absolute;
      top: 18px;
transform: rotate(180deg);
left: 3px;
      background: #FF2626;
width: 25px;
aspect-ratio: 1 / cos(30deg);
-webkit-mask: 
    conic-gradient(from -30deg at 50% calc(200% - 6px), #000 60deg, #0000 0) 
    0 100% / 100% calc(100% - 6px) no-repeat, 
    radial-gradient(4px at 50% calc(8px), #000 98%, #0000 101%), 
    radial-gradient(4px, #000 98%, #0000 101%) space no-repeat 
    0 100% / calc(2 * tan(60deg) * 4px) calc(8px);
clip-path: polygon(50% 0, 100% 100%, 0 100%);
  "></div>
</div>
      <span style="transform: translate(50%, 50%);
    top: -27%;
left: -27%;; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%);"><img src="/icons/close.png"width="10px" /></span>
  </div>
  <div style="display: flex; margin-top: px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #FF2626;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #FF2626;"></div>
  </div>
      <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#FF2626;">${loc?.name}</p>
</div>`
            }
            else if(loc?.type==="Hub" && isHubLegendChecked){
         
              return `<div class="closed-hub fade-in" style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label ">
              <div style=" position: relative; ">
  <div style="
position: relative;">
    <div class="tri a"></div>
<div class="tri alt b"></div>
<div class="tri alt c"></div>
<div class="tri alt d"></div>
<div class="tri alt e"></div>
</div>
      <span style="transform: translate(50%, 50%);
top: 19%;
left: -57%; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%);"><img src="/icons/close.png"width="10px" /></span>
  </div>
  <div style="display: flex; margin-top: 22px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #FF2626;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #FF2626;"></div>
  </div>
              <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#FF2626;">${loc?.name}</p>
          </div>
          
          `
            } 
          }
          else  if(loc?.category==="Missing"){
            if(loc?.type==="Agent" && isAgentLegendChecked){
              return ` <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
              <div style="width: 30px; height: 30px;  border-radius: 6px; display: flex; justify-content: center; align-items: center; position: relative; background-color: #808080;">
                    <span style="border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;  background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); ">-</span>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center; position: relative;">
                   <div style="height:21px; border:1px solid #808080;"></div>
                   <div style="height:8px; border-radius: 50px; width: 8px; background-color: #808080;"></div>
                </div>
               <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#808080;">${loc?.name}</p>
              </div>  `
                          }
                          else if(loc?.type==="Station" && isStationLegendChecked){
                        
                            //missing
                            return `
                            <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
                 <div style=" position: relative; ">
                <div style="position: relative;">
                  <div class="trie " style="    width: 31px;
                  height: 31px;
                  background: #808080;
                  border-radius: 50px;"></div>
                  <div class="tri " style="
                    position: absolute;
                    top: 18px;
              transform: rotate(180deg);
              left: 3px;
                    background: #808080;
              width: 25px;
              aspect-ratio: 1 / cos(30deg);
              -webkit-mask: 
                  conic-gradient(from -30deg at 50% calc(200% - 6px), #000 60deg, #0000 0) 
                  0 100% / 100% calc(100% - 6px) no-repeat, 
                  radial-gradient(4px at 50% calc(8px), #000 98%, #0000 101%), 
                  radial-gradient(4px, #000 98%, #0000 101%) space no-repeat 
                  0 100% / calc(2 * tan(60deg) * 4px) calc(8px);
              clip-path: polygon(50% 0, 100% 100%, 0 100%);
                "></div>
              </div>
                    <span style="transform: translate(50%, 50%);
                  top: -27%;
              left: -27%;; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;  background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%);">-</span>
                </div>
                <div style="display: flex; margin-top: px; flex-direction: column; align-items: center; position: relative;">
                   <div style="height:21px; border:1px solid #808080;"></div>
                   <div style="height:8px; border-radius: 50px; width: 8px; background-color: #808080;"></div>
                </div>
                    <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#808080;">${loc?.name}</p>
            </div>`
                          }
                          else if(loc?.type==="Hub" && isHubLegendChecked){
                       
                            return `<div class="missing-hub fade-in" style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;">
                             <div style=" position: relative; ">
                <div style="
              position: relative;">
                  <div class="tri a"></div>
          <div class="tri alt b"></div>
          <div class="tri alt c"></div>
          <div class="tri alt d"></div>
          <div class="tri alt e"></div>
              </div>
                    <span style="transform: translate(50%, 50%);
              top: 19%;
              left: -57%; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%);">-</span>
                </div>
                <div style="display: flex; margin-top: 22px; flex-direction: column; align-items: center; position: relative;">
                   <div style="height:21px; border:1px solid #808080;"></div>
                   <div style="height:8px; border-radius: 50px; width: 8px; background-color: #808080;"></div>
                </div>
                            <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#808080;">${loc?.name}</p>
                        </div>
                        
                        `
                          } 
          
          }
          }
          if (selectedColumn === 'Open') {
            if(loc?.type==="Agent" && isAgentLegendChecked){
              return `
              <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
                <div style="width: 30px; height: 30px;  border-radius: 6px; display: flex; justify-content: center; align-items: center; position: relative; background-color: #107E5F;">
      <span style="border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
  </div>
  <div style="display: flex; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #107E5F;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #107E5F;"></div>
  </div>
                <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#107E5F;">${loc?.name}</p>
              </div>`
            }
            else if(loc?.type==="Station" && isStationLegendChecked){
              return `
              <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
  
<div style=" position: relative; ">
  <div style="position: relative;">
    <div class="trie " style="    width: 31px;
    height: 31px;
    background: #107E5F;
    border-radius: 50px;"></div>
    <div class="tri " style="
      position: absolute;
      top: 18px;
transform: rotate(180deg);
left: 3px;
      background: #107E5F;
width: 25px;
aspect-ratio: 1 / cos(30deg);
-webkit-mask: 
    conic-gradient(from -30deg at 50% calc(200% - 6px), #000 60deg, #0000 0) 
    0 100% / 100% calc(100% - 6px) no-repeat, 
    radial-gradient(4px at 50% calc(8px), #000 98%, #0000 101%), 
    radial-gradient(4px, #000 98%, #0000 101%) space no-repeat 
    0 100% / calc(2 * tan(60deg) * 4px) calc(8px);
clip-path: polygon(50% 0, 100% 100%, 0 100%);
  "></div>
</div>
      <span style="transform: translate(50%, 50%);
    top: -27%;
left: -27%;; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
  </div>
  <div style="display: flex; margin-top: px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #107E5F;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #107E5F;"></div>
  </div>
      <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#107E5F;">${loc?.name}</p>
</div>`
            }
            else if(loc?.type==="Hub" && isHubLegendChecked){
              return `<div class="open-Hub fade-in" style="display: flex; position: relative; top: -25px;flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label">
              <div style=" position: relative; ">
  <div style="
position: relative;">
    <div class="tri a"></div>
<div class="tri alt b"></div>
<div class="tri alt c"></div>
<div class="tri alt d"></div>
<div class="tri alt e"></div>
</div>
      <span style="transform: translate(50%, 50%);
top: 19%;
left: -57%; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
  </div>
  <div style="display: flex; margin-top: 22px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #107E5F;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #107E5F;"></div>
  </div>
              <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#107E5F;">${loc?.name}</p>
          </div>
          
          `
            }
          
          }
          else if(selectedColumn === 'Limited'){
            if(loc?.type==="Agent" && isAgentLegendChecked){
              return `
              <div style="position: relative; top: -25px; display: flex; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
                 <div style="width: 30px; height: 30px;  border-radius: 6px; display: flex; justify-content: center; align-items: center; position: relative; background-color: #238EF1;">
      <span style="border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
  </div>
  <div style="display: flex; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #238EF1;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #238EF1;"></div>
  </div>
                <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#238EF1;">${loc?.name}</p>
              </div>`
            }
            else if(loc?.type==="Station" && isStationLegendChecked){
              return `
              <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
  <div style=" position: relative; ">
  <div style="position: relative;">
    <div class="trie " style="    width: 31px;
    height: 31px;
    background: #238EF1;
    border-radius: 50px;"></div>
    <div class="tri " style="
      position: absolute;
      top: 18px;
transform: rotate(180deg);
left: 3px;
      background: #238EF1;
width: 25px;
aspect-ratio: 1 / cos(30deg);
-webkit-mask: 
    conic-gradient(from -30deg at 50% calc(200% - 6px), #000 60deg, #0000 0) 
    0 100% / 100% calc(100% - 6px) no-repeat, 
    radial-gradient(4px at 50% calc(8px), #000 98%, #0000 101%), 
    radial-gradient(4px, #000 98%, #0000 101%) space no-repeat 
    0 100% / calc(2 * tan(60deg) * 4px) calc(8px);
clip-path: polygon(50% 0, 100% 100%, 0 100%);
  "></div>
</div>
      <span style="transform: translate(50%, 50%);
    top: -27%;
left: -27%;; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
  </div>
  <div style="display: flex; margin-top: px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #238EF1;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #238EF1;"></div>
  </div>
      <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#238EF1;">${loc?.name}</p>
</div>`
            }
            else if(loc?.type==="Hub" && isHubLegendChecked){
              return `<div class="limited-hub fade-in"  style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label">
             <div style=" position: relative; ">
  <div style="
position: relative;">
    <div class="tri a"></div>
<div class="tri alt b"></div>
<div class="tri alt c"></div>
<div class="tri alt d"></div>
<div class="tri alt e"></div>
</div>
      <span style="transform: translate(50%, 50%);
top: 19%;
left: -57%; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;">${loc?.location?.backlog || 0}</span>
  </div>
  <div style="display: flex; margin-top: 22px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #238EF1;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #238EF1;"></div>
  </div>
              <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#238EF1;">${loc?.name}</p>
          </div>
          
          `
            } 
          }
          else if(selectedColumn === 'Closed'){
            if(loc?.type==="Agent" && isAgentLegendChecked){
              return `
              <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
                  <div style="width: 30px; height: 30px;  border-radius: 6px; display: flex; justify-content: center; align-items: center; position: relative; background-color: #FF2626;">
      <span style="border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%);"><img src="/icons/close.png"width="10px" /></span>
  </div>
  <div style="display: flex; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #FF2626;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #FF2626;"></div>
  </div>
                <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#FF2626;">${loc?.name}</p>
              </div>`
            }
            else if(loc?.type==="Station" && isStationLegendChecked){
              return `
              <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
 <div style=" position: relative; ">
  <div style="position: relative;">
    <div class="trie " style="    width: 31px;
    height: 31px;
    background: #FF2626;
    border-radius: 50px;"></div>
    <div class="tri " style="
      position: absolute;
      top: 18px;
transform: rotate(180deg);
left: 3px;
      background: #FF2626;
width: 25px;
aspect-ratio: 1 / cos(30deg);
-webkit-mask: 
    conic-gradient(from -30deg at 50% calc(200% - 6px), #000 60deg, #0000 0) 
    0 100% / 100% calc(100% - 6px) no-repeat, 
    radial-gradient(4px at 50% calc(8px), #000 98%, #0000 101%), 
    radial-gradient(4px, #000 98%, #0000 101%) space no-repeat 
    0 100% / calc(2 * tan(60deg) * 4px) calc(8px);
clip-path: polygon(50% 0, 100% 100%, 0 100%);
  "></div>
</div>
      <span style="transform: translate(50%, 50%);
    top: -27%;
left: -27%;; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%);"><img src="/icons/close.png"width="10px" /></span>
  </div>
  <div style="display: flex; margin-top: px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #FF2626;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #FF2626;"></div>
  </div>
      <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#FF2626;">${loc?.name}</p>
</div>`
            }
            else if(loc?.type==="Hub" && isHubLegendChecked){
         
              return `<div class="closed-hub fade-in" style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label">
              <div style=" position: relative; ">
  <div style="
position: relative;">
    <div class="tri a"></div>
<div class="tri alt b"></div>
<div class="tri alt c"></div>
<div class="tri alt d"></div>
<div class="tri alt e"></div>
</div>
      <span style="transform: translate(50%, 50%);
top: 19%;
left: -57%; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%);"><img src="/icons/close.png"width="10px" /></span>
  </div>
  <div style="display: flex; margin-top: 22px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #FF2626;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #FF2626;"></div>
  </div>
              <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#FF2626;">${loc?.name}</p>
          </div>
          
          `
            } 
  
          }
          else if (selectedColumn === 'Missing') {
if(loc?.type==="Agent" && isAgentLegendChecked){
return ` <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label fade-in">
<div style="width: 30px; height: 30px;  border-radius: 6px; display: flex; justify-content: center; align-items: center; position: relative; background-color: #808080;">
      <span style="border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;  background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%); ">-</span>
  </div>
  <div style="display: flex; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #808080;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #808080;"></div>
  </div>
 <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#808080;">${loc?.name}</p>
</div>  `
            }
            else if(loc?.type==="Station" && isStationLegendChecked){
          
              //missing
              return `
              <div style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;" class="map-data-label fade-in">
   <div style=" position: relative; ">
  <div style="position: relative;">
    <div class="trie " style="    width: 31px;
    height: 31px;
    background: #808080;
    border-radius: 50px;"></div>
    <div class="tri " style="
      position: absolute;
      top: 18px;
transform: rotate(180deg);
left: 3px;
      background: #808080;
width: 25px;
aspect-ratio: 1 / cos(30deg);
-webkit-mask: 
    conic-gradient(from -30deg at 50% calc(200% - 6px), #000 60deg, #0000 0) 
    0 100% / 100% calc(100% - 6px) no-repeat, 
    radial-gradient(4px at 50% calc(8px), #000 98%, #0000 101%), 
    radial-gradient(4px, #000 98%, #0000 101%) space no-repeat 
    0 100% / calc(2 * tan(60deg) * 4px) calc(8px);
clip-path: polygon(50% 0, 100% 100%, 0 100%);
  "></div>
</div>
      <span style="transform: translate(50%, 50%);
    top: -27%;
left: -27%;; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold;  background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%);">-</span>
  </div>
  <div style="display: flex; margin-top: px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #808080;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #808080;"></div>
  </div>
      <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#808080;">${loc?.name}</p>
</div>`
            }
            else if(loc?.type==="Hub" && isHubLegendChecked){
         
              return `<div class="missing-hub fade-in" style="display: flex; position: relative; top: -25px; flex-direction: column; align-items: center;cursor:pointer;"class="map-data-label">
               <div style=" position: relative; ">
  <div style="
position: relative;">
    <div class="tri a"></div>
<div class="tri alt b"></div>
<div class="tri alt c"></div>
<div class="tri alt d"></div>
<div class="tri alt e"></div>
</div>
      <span style="transform: translate(50%, 50%);
top: 19%;
left: -57%; position:absolute; border-radius: 50px; display: flex; justify-content: center; align-items: center;  width: 24px; height: 24px; color: black; font-size: 14px; font-weight: bold; background: linear-gradient(90deg, #FAFAFA -0.17%, #B7C9DB 100.03%);">-</span>
  </div>
  <div style="display: flex; margin-top: 22px; flex-direction: column; align-items: center; position: relative;">
     <div style="height:21px; border:1px solid #808080;"></div>
     <div style="height:8px; border-radius: 50px; width: 8px; background-color: #808080;"></div>
  </div>
              <p style="margin-top: 3px;font-family: 'Product Sans';font-size:14px;font-weight:700;color:#808080;">${loc?.name}</p>
          </div>
          
          `
            } 


       }
        }
      },
      tooltipData: {
        locationName:loc?.name,
        issue: loc?.location?.issue_detail, 
      status_date: loc?.location?.created_at,
      name:loc?.location?.created_by_name,
      backlog: loc?.location?.backlog, 
      staffing: loc?.location?.staffing, 
      }
    };
  });
  }, [filteredLocations,selectedColumn,zoomedViewState,isAgentLegendChecked,isHubLegendChecked,isStationLegendChecked]);
  useEffect(() => {
    
    const fetchData = async () => {
      // Fetch map data
      const topology = await fetch(
        'https://code.highcharts.com/mapdata/countries/us/us-all.topo.json'
      ).then(response => response?.json());


      // Prepare demo data
      const data = [
        ['us-ma', 10], ['us-wa', 11], ['us-ca', 12], ['us-or', 13],
        ['us-wi', 14], ['us-me', 15], ['us-mi', 16], ['us-nv', 17],
        ['us-nm', 18], ['us-co', 19], ['us-wy', 20], ['us-ks', 21],
        ['us-ne', 22], ['us-ok', 23], ['us-mo', 24], ['us-il', 25],
        ['us-in', 26], ['us-vt', 27], ['us-ar', 28], ['us-tx', 29],
        ['us-ri', 30], ['us-al', 31], ['us-ms', 32], ['us-nc', 33],
        ['us-va', 34], ['us-ia', 35], ['us-md', 36], ['us-de', 37],
        ['us-pa', 38], ['us-nj', 39], ['us-ny', 40], ['us-id', 41],
        ['us-sd', 42], ['us-ct', 43], ['us-nh', 44], ['us-ky', 45],
        ['us-oh', 46], ['us-tn', 47], ['us-wv', 48], ['us-dc', 49],
        ['us-la', 50], ['us-fl', 51], ['us-ga', 52], ['us-sc', 53],
        ['us-mn', 54], ['us-mt', 55], ['us-nd', 56], ['us-az', 57],
        ['us-ut', 58], ['us-hi', 59], ['us-ak', 60]
      ];
      // Create the chart
   const  chart= Highcharts.mapChart('container', {
        chart: {
          map: topology,
          backgroundColor: 'transparent',
          width:chartWidth,
          height:chartHeight,
        },

        title: {
          text: ''
        },
       
        subtitle: {
        //   text: 'Click on the images to see details'
        },

        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: 'bottom',
            align: 'right'
          }
        },

        colorAxis: {
          min: 0,
          minColor: '#F9F9F9',
          maxColor: '#F9F9F9'
        },
        tooltip: {
          enabled: true,
          useHTML: true,
          positioner: function (labelWidth, labelHeight, point) {
            const x = point.plotX + 30; 
            const y = point.plotY - (labelHeight / 2) + this.chart.plotTop;
            return { x, y };
          },
          hideDelay: 10, 
          formatter: function () {
            const data = this.point.tooltipData;
            let issueColor = '#000'; 
            if (data?.staffing===100) {
              issueColor = '#107E5F'; 
          } else if (data?.staffing===75) {
              issueColor = '#238EF1'; 
          }else if (data?.staffing===50) {
            issueColor = '#FFC300'; 
        }
        else if (data?.staffing===25) {
          issueColor = '#FF8227';
      }
      else if (data?.staffing===0) {
        issueColor = '#FF2626';
    }
      else{
        issueColor = '#000'; 
      }
            return `
                            <div style="display: flex; justify-content: space-between; flex-direction:column;width:170px;border-radius:6px"> 
                          <div style="display: flex;margin-top: 5px;font-size:14px;font-weight:700;color:#1C254C;font-family: Product Sans;line-height: 16.98px;">${data?.locationName ?data?.locationName:""}</div>
                          
                          </div>
                          <div style="border-top: 1px solid #E6E6E6; margin-top: 8px;"></div>
            <div style="display: flex; justify-content: space-between; flex-direction:column;width:170px;border-radius:6px;margin-top: 8px;"> 
                          <div style="font-family: Product Sans;display: flex;margin-top: 5px;font-size:12px;font-weight:400;color:#606060;line-height: 14.56px;">Reason</div>
                           <div style="font-family: Product Sans;display: flex; justify-content: space-between; align-items: center;margin-top:8px;font-size:14px;font-weight:400;color:${issueColor}";line-height: 16.98px;>
                            <div>${data?.issue?data?.issue:"None"}</div>
                         
                            </div>
                          </div>
                          <div style="border-top: 1px solid #E6E6E6; margin-top: 8px;"></div>
            
                               <div style="display: flex; justify-content: space-between; flex-direction:column;width:170px;border-radius:6px;margin-top: 8px;"> 
                          <div style="font-family: Product Sans;display: flex;margin-top: 5px;font-size:12px;font-weight:400;color:#606060;line-height: 14.56px;">Last Updated</div>
                           <div style="display: flex; justify-content: space-between; align-items: center;margin-top:8px;font-size:14px;font-weight:400" class="gap-1">
                            <div style="font-size:14px;font-weight:400;font-family: Product Sans;line-height: 16.98px;color:#2C2C2C;text-overflow: ellipsis; overflow: hidden;">${data?.name?data?.name:"-"}</div>
                            <div style="font-size:14px;font-weight:400;font-family: Product Sans;line-height: 16.98px;color:#2C2C2C;">${data?.status_date? data?.status_date:"-"}</div>
                            </div>
                          </div>
                          <div style="border-top: 1px solid #E6E6E6; margin-top: 8px;"></div>
                          
            
                        
            
            
                           <div style="display: flex; justify-content: space-between; flex-direction:column;width:170px;border-radius:6px;margin-top: 8px;"> 
                          <div style="display: flex;margin-top: 5px;font-size:12px;font-weight:400;color:#606060;font-family: Product Sans;color:#606060;line-height: 14.56px;">Days Behind</div>
                           <div style="display: flex; justify-content: space-between; align-items: center;margin-top:8px;font-size:14px;font-weight:400">
                            <div style="font-size:14px;font-weight:400;font-family: Product Sans;line-height: 16.98px;color:#2C2C2C;">${data?.backlog !== undefined ? data.backlog : '-'}</div>
                         
                            </div>
                          </div>
                          `;
          },
          outside: true
        },
        
        plotOptions: {
          series: {
              states: {
                  inactive: {
                      opacity: 1 
                  }
              }
          }
      },
     
        series: [{
          data: [],
          name: 'Random data',
          borderColor: '#000',
          // states: {
          //   hover: {
          //     color: '#EDF1FF80',
          //     //  borderColor:"#FF2626"
          //   },
          //            select: {
          //   color: '#EFFFEF',
          //   borderColor: 'black',
          //   // dashStyle: 'dot'
          // }
          // },
          
            //  allowPointSelect: true,
            cursor: 'pointer',
           
          dataLabels: {
            enabled: false,
            useHTML: false,         
          },
          
          point: {
            events: {
              load() {
                const mapView = this.mapView;
                if (mapView) {
                  setInitialView({
                    center: mapView.center,
                    zoom: mapView.zoom,
                  });
                }
              },
            
                click: function (event) { 
                  },
                
            }
          }
        },
        
        {
          id: 'dataSeries',
          type: 'mappoint',
          name: 'States',
          data: dataPoints,
          marker: {
            enabled: false 
          },
          point: {
            events: {
              click: function (event) {
                const { lat, lon, name, id } = this;
                handleStateClick(name, id);
              }
            }
          } 
        }
      ]
      });
      setChartInstance(chart);
    };

    fetchData();
  }, []);
  useEffect(() => {
    setChartWidth(showTimeline ? window.innerWidth-430 : window.innerWidth-100);
  }, [showTimeline]);
  useEffect(() => {

    const mapSeries = chartInstance?.series?.find(series => series?.userOptions?.id === 'dataSeries');
    if (mapSeries) {
      mapSeries?.update({
        data: dataPoints
      }, true, true); 
    } 
  },[dataPoints,chartInstance,isAgentLegendChecked, isStationLegendChecked, isHubLegendChecked,filteredLocations,selectedColumn]);
  useEffect(() => {
    if (chartInstance) {
      try {
        chartInstance?.setSize(chartWidth, chartHeight, false);
      } catch (error) {
        console.error("Error updating chart size:", error);
      }
    }
  
  }, [chartWidth, chartHeight, chartInstance ]); 
  useEffect(() => {
    if (zoomOutVisible) {
      try {
        chartInstance?.setSize(chartWidth,  window.innerHeight - 160, true);
      } catch (error) {
        console.error("Error updating chart size:", error);
      }
    }
    else{
      chartInstance?.setSize(chartWidth,  window.innerHeight - 200, true);
    }
  }, [zoomOutVisible, chartWidth,chartInstance]); 
  const handleStateClick = async(stateName,id) => {
    setLoading(true)
    setValue(stateName)
    setLocationById(id)

    try {
      const res = await httpClient?.get(`/api/location-statuses/get/${id}`);
      if(res?.status===200){
        setLoading(false)
        setLastStatusid(id)
        const timelineEvents = res?.data
        setEvents(timelineEvents);
        setShowTimeline(true);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }

  };

  const zoomOut = ()=>{
    setZoomOutVisible(false);
    chartInstance?.mapView?.setView(initialView.center, initialView.zoom);
    setZoomedViewState({
      center: initialView.center,
      zoom: initialView.zoom,
    });

    setSelectedDirection(null);
  }

  const zoomRegion = (latitude, longitude, zoom, direction) => {
    if (chartInstance && chartInstance.mapView) {
      if (selectedDirection === direction) {
        setZoomOutVisible(false);
        chartInstance.mapView.setView(initialView.center, initialView.zoom);
        setZoomedViewState({
          center: initialView.center,
          zoom: initialView.zoom,
        });

        setSelectedDirection(null);
      } else {
        setZoomOutVisible(true);
        chartInstance.mapView.setView([latitude, longitude], zoom);
        setZoomedViewState({
          center: [latitude, longitude],
          zoom: zoom,
        });
        setSelectedDirection(direction);
      }
    } else {
      console.error('mapView is not available or chart is not initialized.');
    }
  };

  const onAddNew = () => {
    setDialogVisible(true);
  };
  const onDownload = async () => {
    setDownloadInProgress(true);
    toast.current.show({ severity: 'success', summary: 'Download', detail: 'Excel Download In Progress', life: 2000 });
  
    try {
      const response = await httpClient?.get('/api/location-statuses/download');
      const downloadUrl = response?.data;
      const filename = downloadUrl.split('/').pop();
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error while downloading the file:', error);
    } finally {
      setDownloadInProgress(false);
    }
  };
  
  const gridActions = [
    { name: '', type: 'icon', icon: "download", onClick: onDownload,disabled: downloadInProgress },
    { name: 'Add Status', type: 'button', className: 'primary-button ', onClick: onAddNew },
  ];
  const handleCheckedChange = (checked) => {
    setIsChecked(checked);
    if (checked) {
      setActiveColumn('All');
      setSelectedColumn('All');
    } else {
      setActiveColumn('Open'); // Reset to default state
      setSelectedColumn('Open');
    }
  };


  const handleColumnClick = (columnName,isZoomed) => {
    if(columnName === 'All'){
      if(zoomOutVisible) {
        zoomOut();
      }
      setIsAgentLegendChecked(true);
      setIsStationLegendChecked(true);
      setIsHubLegendChecked(true);
    }
    setSelectedColumn(columnName);
    setActiveColumn(columnName);
    if (columnName !== 'All') {
      setIsChecked(false);
  }
 
  };
  const refreshLocations = async () => {
    await StatusLocations();  
  };

  const refreshTimeLine = async (stateName, id) => { 
    await StatusLocations(); 
    await handleStateClick(stateName, id); 
  };

  const handleLegendCheck = (type)=>{
    if(type==='agent'){
      setIsAgentLegendChecked(!isAgentLegendChecked);
     
    }
    else if(type==='station'){
      setIsStationLegendChecked(!isStationLegendChecked);
     
    }
    else if(type==='hub'){
      setIsHubLegendChecked(!isHubLegendChecked);
     
    }
   
    
  }

  useEffect(() => {
    StatusLocations();
  }, []);
  return (
    <div className='main-page-layout'>
       <Toast ref={toast} position="top-center" />
          <StatusUpdateDialog visible={dialogVisible} onHide={setDialogVisible} onRefresh={refreshTimeLine} />
            <PageHeader title={"Map"} actions={gridActions}/>
            <TopWeather isChecked={isChecked} onCheckedChange={handleCheckedChange}  totalcount={locations}/>
            <StatusSection
        activeColumn={activeColumn}
        onColumnClick={handleColumnClick}
        totalcount={locations}
        isZoomed = {zoomOutVisible}
      />
   <div className="flex" >
  {showTimeline && (
    <div className="flex-shrink-0 surface-0 shadow-2 border-1 border-50 border-round  base-font p-2" style={{ width: '25%' }}>
      <div className="flex justify-content-between align-items-center p-2">
        <span style={{ fontSize: "18px" }}>Last Updated ({value ? value : ""})</span>
        <i className="pi pi-times cursor-pointer" style={{ fontSize: '1rem' }} onClick={() => setShowTimeline(false)}></i>
      </div>
      <hr />
      <div className='history-timeline'>
        <CustomTimeline events={events} onRefresh={refreshTimeLine} locationValue={locationById} laststatusid={laststatusid} />
      </div>
    </div>
  )}

  <div style={{ flex: showTimeline ? '1' : '100%',flexBasis: showTimeline ? '75%' : '100%',maxWidth: showTimeline ? '74.5%' : '100%', marginLeft: showTimeline ? '0.5rem' : '0' }}>
    
    <section className=' flex overflow-hidden surface-0 shadow-2 border-1 border-50 border-round'>
    <div id="container" className="compass-container" style={{ height: `calc(100vh - ${zoomOutVisible? '235px' :'270px'})` }} />
    <div className='zoom-out-cont flex flex-column align-items-center gap-3'>
    { zoomOutVisible && <button className={`mb-7 zoom-out-btn flex justify-content-center align-items-center`} onClick={() => zoomOut()}> <Icon className="cursor-pointer pt-1" type="svg" name="zoom-out"/> </button>}
    <div className="compass d-flex justify-content-center ">
      <button className={`compass-button ${selectedDirection === 'N' ? 'button-success' : ''}`} onClick={() => zoomRegion(35.2033, -77.1945, window.innerWidth >=1920 && window.innerHeight>=900 ? 6.1 : 5.5, 'N')}>N</button>
      <button className={`compass-button ${selectedDirection === 'S' ? 'button-success' : ''}`} onClick={() => zoomRegion(32.1656, -82.9001,window.innerWidth>=1920 && window.innerHeight>=900 ? 6.1 : 5.5, 'S')}>S</button>
      <button className={`compass-button ${selectedDirection === 'E' ? 'button-success' : ''}`} onClick={() => zoomRegion( -81,38,window.innerWidth>=1920 && window.innerHeight>=900 ? 6.3 : 5.8, 'E')}>E</button>
      <button className={`compass-button ${selectedDirection === 'W' ? 'button-success' : ''}`} onClick={() => zoomRegion( -111.41,38.57,window.innerWidth>=1920 && window.innerHeight>=900 ? 6.3 : 5.8, 'W')}>W</button>
    </div>
    </div>

    <div id='legends' className='legend-container px-2'>

        <div id='agent-legend' className='pb-2 pt-2 w-full flex justify-content-between align-items-center legend'>
          <div className='flex align-items-center gap-2'>
          <Icon className="" type="svg" name="square-legend"/>
            <p className={`cursor-pointer ${!isAgentLegendChecked?"inactive-legend":"active-legend"}`} onClick={()=>{handleLegendCheck('agent')}}>Agent</p>
            
          </div>
          <input checked={isAgentLegendChecked} onClick={()=>{handleLegendCheck('agent')}} className='checkbox' type="checkbox"/>

        </div>

        <div id='station-legend' className='pb-2 pt-2 w-full flex justify-content-between align-items-center legend'>
          <div className='flex align-items-center gap-2'>
          <Icon className="" type="svg" name="circle-legend"/>
            <p className={`cursor-pointer ${!isStationLegendChecked?"inactive-legend":"active-legend"}`} onClick={()=>{handleLegendCheck('station')}}>Station</p>
            
          </div>
          <input checked={isStationLegendChecked} onClick={()=>{handleLegendCheck('station')}} className='checkbox' type="checkbox"/>

        </div>

        <div id='hub-legend' className='pb-2 pt-2 w-full flex justify-content-between align-items-center'>
          <div className='flex align-items-center gap-2'>
          <Icon className="" type="svg" name="star-legend"/>
            <p className={`cursor-pointer ${!isHubLegendChecked?"inactive-legend":"active-legend"}`} onClick={()=>{handleLegendCheck('hub')}}>Hub</p>
            
          </div>
          <input className='checkbox' checked={isHubLegendChecked} onClick={()=>{handleLegendCheck('hub')}} type="checkbox"/>

        </div>

    </div>
    </section>
    
  </div>
</div>

    </div>
  );
};

export default USMap;
