import React, { useEffect, useRef, useState } from 'react';
import ApplicationLogoWhite from './ApplicationLogoWhite';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Badge } from 'primereact/badge';
import Dropdown from '../shared/Dropdown';
import HelpNotificationOverlay from './HelpNotificationOverlay';
import NotificationOverlay from './NotificationOverlay';
import { Link, useNavigate } from 'react-router-dom';
import Icon from '../shared/Icon';
import httpClient from './api';
import useUserStore from '../../store/UserStore';
const Navbar = () => {
    const setLoading = useUserStore((state) => state.setLoading)
    const setUserInfo = useUserStore((state) => state.setUserInfo)
    const userinfo=useUserStore((state) => state.userInfo)
    const notifOP = useRef(null);
    const helpnotifOP = useRef(null);
    const homeRoute = `${process.env.REACT_APP_API_URL}/home`;
    const settingsRoute = `${process.env.REACT_APP_API_URL}/admin/customers`;
    const navigate=useNavigate();
    const extractTokenFromUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        
        if (token) {
            localStorage.setItem('user-token', token);
            
            // Remove token from URL
            urlParams.delete('token');
            const newUrl = window.location.pathname + (urlParams.toString() ? `?${urlParams.toString()}` : '');
            window.history.replaceState({}, document.title, newUrl);
        }
    };
 
    const fetchUserData = async () => {
        setLoading(true);
        try {
          const response = await httpClient.get(`/api/user`);
          if(response.status===200){
              setUserInfo(response?.data)
              setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false)
        }
      };
    useEffect(()=>{  
        extractTokenFromUrl();  
          fetchUserData();     
     },[])
     const [notifications,setNotifications]=useState();
     const [unread_notifications,setUnreadNotifications]=useState();
     const fetchNotifications = async () => {
         try {
           const response = await httpClient.get(`/api/user/notification/${userinfo?.id}`);
           if(response.status===200){
             setNotifications(response?.data)
           }
         } catch (error) {
           console.log(error);
         }
       };
       const fetchUnreadNotifications = async () => {
        try {
          const response = await httpClient.get(`/api/user/unread-notification/${userinfo?.id}`);
          if(response?.status===200){
            setUnreadNotifications(response?.data)
          }
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        if (userinfo?.id) {
            fetchNotifications();
            fetchUnreadNotifications();
        }
    }, [userinfo?.id]);
    const profilePage =()=>{
navigate("/profile");
    }
   const  HandleLogout= async()=>{
    try {
        const res = await httpClient.post(`/api/logout`);
        if (res.status===200){
            fetchUserData();
        }
      } catch (error) {
        console.log(error);
      }
   
    
   }
   const notificationrefersh=()=>{
    fetchNotifications();
    fetchUnreadNotifications();
   }
   const closePanel=()=>{
    notifOP.current.hide();
   }

    return (
        <nav className="bg-header border-b border-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6">
                <div className={`flex justify-content-between align-items-center h-16`}>
                    {
                       
                       <Link to={homeRoute}>
                            <ApplicationLogoWhite
                                className={`mt-1 mb-1 h-9 w-auto fill-current text-gray-800`}
                            />
                      </Link>
                    }

                    <div className="flex align-items-center ">
                       
                        <div className="flex align-items-center ">
                        {
                         <Link to={homeRoute}>
                             <Icon
                             className="cursor-pointer mx-2 my-1"
                             type="svg"
                             name="allapps"
                         />
                         </Link>
                            // <img src={home}  className="cursor-pointer mx-2 my-1" alt='home'></img>
                             
                            } 
                              {
                                userinfo?.role_id === 1&&
                                <Link to={settingsRoute}>
                                <Icon
                                className="cursor-pointer mx-2 my-1"
                                type="svg"
                                name="setting"
                            />
                            </Link>
                            //  <img src={setting} className='cursor-pointer mx-2 my-1' alt='setting'></img>
     
                            }
                             {/* {
                                 <Icon
                                 className="mx-2  cursor-pointer"
                                 type="svg"
                                 name="help"
                                 onClick={(e) => { helpnotifOP.current.toggle(e) }}
                             /> 
                            //  <img src={help} className='cursor-pointer mx-2 my-1' onClick={(e) => { helpnotifOP.current.toggle(e) }} alt='help'></img>  
                            } */}
                             {
                         
                         <span className="">
                           <Icon
                                    className="mx-2 cursor-pointer"
                                    type="svg"
                                    name="notification"
                                    onClick={(e) => { notifOP.current.toggle(e) }}
                                />                {
                               unread_notifications?.length>0?
                               <Badge className="notification-badge" severity="danger"></Badge>:""
                             } 
                         </span>
                     }
                        </div>
                       
                        <OverlayPanel ref={notifOP} className='notification-overlay' onHide={() => { notifOP.current.hide() } }>
                            <NotificationOverlay closeOverlay={() => { notifOP.current.hide() }} notifications={notifications} unread_notifications={unread_notifications} onrefresh={notificationrefersh} onclose={closePanel}/>
                        </OverlayPanel>
                           
                        <OverlayPanel ref={helpnotifOP} className='notification-overlay' onHide={() => { helpnotifOP.current.hide() } }>
                            <HelpNotificationOverlay  closeOverlay={() => { helpnotifOP.current.hide() }} />
                        </OverlayPanel>
                           <div>
                            {/* <Dropdown>
                                <Dropdown.Trigger> */}
                                    {/* <span className="inline-flex rounded-md">
                                        <button
                                            type="button"
                                            className="cursor-pointer inline-flex align-items-center  py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-header hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                                        >
                                           
                                           <Icon
                                                className="border-circle border-transparent mr-2"
                                                type="png"
                                                name="usaflag"
                                            /> */}
                                             {/* <img src={usaflag} className="border-circle border-transparent mr-2" width={35} height={35} alt='flag'></img> */}
                                            
                                            
                                            {/* <Icon
                                                className=""
                                                type="svg"
                                                name="down-arrow"
                                            /> */}
                             
                                        {/* </button>
                                    </span>
                                </Dropdown.Trigger> */}

                                {/* <Dropdown.Content classNames={'top-bar-dopdown-position2'}> */}
                                    {/* <Dropdown.Link > */}
                                        {/* <div className='flex align-items-center mb-2 '>
                                        <Icon
                                                className="border-circle border-transparent mr-1"
                                                type="svg"
                                                name="usa"
                                                width={35} height={35}
                                            />
                                        <span className='profile-flag'>USA</span>  
                                        </div> */}
                                        {/* <div className='flex align-items-center mb-2  '> */}
                                    {/* <img src={canadaflag} className="border-circle border-transparent mr-1" width={35} height={35}alt='flag'></img> */}
                                    {/* <Icon
                                                className="border-circle border-transparent mr-1"
                                                type="svg"
                                                name="canada"
                                                width={35} height={35}
                                            />
                                        <span className='profile-flag'>Canada</span>  
                                        </div> */}
                                        {/* <div className='flex align-items-center   '>
                                        <Icon
                                                className="border-circle border-transparent mr-1"
                                                type="svg"
                                                name="mexico"
                                                width={35} height={35}
                                            /> */}
                                    {/* <img src={mexicoflag} className="border-circle border-transparent mr-1" width={35} height={35} alt='flag'></img> */}
                                        {/* <span className='profile-flag'> Mexico</span>  
                                        </div> */}
                                    {/* </Dropdown.Link> */}
                                    {/* <Dropdown.Link className="pr-4"
              
                                    >
                                    </Dropdown.Link> */}
                                {/* </Dropdown.Content> */}
                            {/* </Dropdown> */}
                        </div>
                        {/* <div>
                            <Dropdown>
                                <Dropdown.Trigger>
                                    <span className="inline-flex rounded-md">
                                        <button
                                            type="button"
                                            className="cursor-pointer inline-flex align-items-center  py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-header hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                                        >
                                        
                                            <img src={userinfo?.profile_picture?userinfo?.profile_picture:""} className="border-circle border-transparent mr-2" width={35} height={35} alt='profile'></img>
                                            
                                            <div className="cursor-pointer">
                                                <label className="text-white font-medium text-sm line-height-2">
                                              {userinfo?.name?userinfo?.name:""}
                                                </label>
                                                <br />
                                                <label className="text-white font-normal text-xs line-height-1">
                                                    {" "}
                                                    {userinfo?.role?userinfo.role:""}
                                                </label>
                                            </div>
                                            <Icon
                                                className="mx-2"
                                                type="svg"
                                                name="down-arrow"
                                            />
                                        </button>
                                    </span>
                                </Dropdown.Trigger>

                                <Dropdown.Content classNames={'top-bar-dopdown-position profile-flag text-left'}>
                                    <Dropdown.Link onClick={profilePage} className='cursor-pointer'>
                                  Profile Settings 
                                    </Dropdown.Link>
                                    <Dropdown.Link className="pr-4 cursor-pointer " onClick={HandleLogout}
              
                                    >
                                        Logout
                                    </Dropdown.Link>
                                </Dropdown.Content>
                            </Dropdown>
                        </div> */}

                            <div>
                            <Dropdown>
                                <Dropdown.Trigger>

                                    <div className='profile-container p-2 pr-3  flex justify-content-between align-items-center cursor-pointer'>
                                        <div className='flex flex-column mr-1 ml-1 '>
                                        <span className="profile-name">
                                              {userinfo?.name?userinfo?.name:""}
                                                </span>
                                               
                                                <span className="profile-role">
                                                    {" "}
                                                    {userinfo?.role?userinfo.role:""}
                                                </span>
                                        </div>

                                        <div className='flex align-items-center gap-2 ml-1'>
                                        <img src={userinfo?.profile_picture?userinfo?.profile_picture:""} className="border-circle border-transparent" width={32} height={32} alt='profile'></img>

                                        <Icon
                                                className=""
                                                type="svg"
                                                name="down-arrow"
                                            />
                                        </div>

                                    </div>
                                   
                                </Dropdown.Trigger>

                                <Dropdown.Content classNames={'top-bar-dopdown-position profile-flag mt-1 text-left'}>
                                    <Dropdown.Link onClick={profilePage} className='cursor-pointer'>
                                  Profile Settings 
                                    </Dropdown.Link>
                                    <Dropdown.Link className="pr-4 cursor-pointer " onClick={HandleLogout}
              
                                    >
                                        Logout
                                    </Dropdown.Link>
                                </Dropdown.Content>
                            </Dropdown>
                        </div>

                     
                    </div>
                </div>
            </div>
        </nav>
    );
}
  


export default Navbar;
