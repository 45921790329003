import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect,useState } from 'react'
import httpClient from './api';
import useUserStore from '../../store/UserStore';
import { Link } from 'react-router-dom';

const ViewAllNotification = () => {
    const userinfo=useUserStore((state) => state.userInfo)
    const setLoading = useUserStore((state) => state.setLoading)
    const { markAsReadNotification, setMarkAsReadNotification } = useUserStore();
    const StatusRoute = `${process.env.REACT_APP_API_URL}/admin/status`;
    const ExceptionRoute = `${process.env.REACT_APP_API_URL}/admin/exceptions`;
    const getTime = (date) => {
        const specificDate = new Date(date);
        const currentDate = new Date();
    
        const timeDifferenceInMilliseconds = currentDate - specificDate;
    
        const minutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
        const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
        const days = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));
        const weeks = Math.floor(days / 7); 
    
        const specificYear = specificDate.getFullYear();
        const currentYear = currentDate.getFullYear();
        const specificMonth = specificDate.getMonth();
        const currentMonth = currentDate.getMonth();
    
        const months = (currentYear - specificYear) * 12 + (currentMonth - specificMonth);
        const years = Math.floor(months / 12);
    
        if (minutes < 60) {
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
        } else if (hours < 24) {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
        } else if (days < 7) {
            return `${days} ${days === 1 ? 'day' : 'days'} ago`;
        } else if (weeks < 4) {
            return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
        } else if (months < 12) {
            return `${months} ${months === 1 ? 'month' : 'months'} ago`;
        } else {
            return `${years} ${years === 1 ? 'year' : 'years'} ago`;
        }
    };
    useEffect(() => {
        if (markAsReadNotification) {
            fetchNotifications();
            fetchUnreadNotifications();
            setMarkAsReadNotification(false); 
        }
      }, [markAsReadNotification, setMarkAsReadNotification]);
    const [notifications,setNotifications]=useState();
    const [unread_notifications,setUnreadNotifications]=useState();
    const fetchNotifications = async () => {
        setLoading(true);
        try {
          const response = await httpClient.get(`/api/user/all-notification/${userinfo?.id}`);
          if(response.status===200){
            setNotifications(response?.data)
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
      const fetchUnreadNotifications = async () => {
        setLoading(true);
       try {
         const response = await httpClient.get(`/api/user/all-unread-notification/${userinfo?.id}`);
         if(response?.status===200){
           setUnreadNotifications(response?.data)
           setLoading(false);
         }
       } catch (error) {
        setLoading(false);
         console.log(error);
       }
     };
     useEffect(() => {
       if (userinfo?.id) {
           fetchNotifications();
           fetchUnreadNotifications();
       }
   }, [userinfo?.id]);
    const onClickView = async(notification) => {
        if(notification?.data?.message==="Download File - Link"){
            const url = notification.data.url;
            const link = document.createElement('a');
            link.href = url;
            link.download = ''; 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        try {
            const response = await httpClient.get(`/api/mark-as-read/${notification?.id}`);
            if(response.status===200){
                fetchNotifications();
                fetchUnreadNotifications();
            }
          } catch (error) {
            console.log(error);
          }

    }

  return (
    <div>
        <div className="pt-3 px-8 notification-view">

<div className="py-2 text-primary text-xl font-bold">
    All Notifications
</div>
<TabView className='notification-tabview'>
    <TabPanel header="All">
        <div className='notification-body overflow-y-scroll'>
            {
                notifications?.length ?
                    notifications?.map((notification, index) => {
                        return (
                            <div onClick={() => onClickView(notification)} key={index} className={`${notification.read_at ? 'read' : 'unread'} px-3 cursor-pointer`}>
                                <div className='pt-3 pb-2 flex justify-content-between'>
                                    <div className='flex flex-column'>
                                    {notification?.data?.message === "New Status Added" ? (
                                            <Link to={StatusRoute} className='no-underline text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</Link>
                                        ) : notification?.data?.message === "New Exception Added" ? (
                                            <Link to={ExceptionRoute} className='no-underline text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</Link>
                                        ) : (
                                            <span className='notif-message text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</span>
                                        )}

                                        <span className='notif-time text-gray-500 text-sm font-normal'>{getTime(notification?.created_at)}</span>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        )
                    })
                    :
                    <div className={`read px-3`}>
                        <div className='pt-3 pb-2 flex justify-content-center'>
                            <span className='text-gray-500 text-base font-normal'>
                                No Notifications
                            </span>
                        </div>
                    </div>
            }
        </div>
    </TabPanel>
    <TabPanel header="Unread">
        <div className='notification-body overflow-y-scroll'>

            {
                unread_notifications?.length ?
                    unread_notifications?.map((notification, index) => {
                        return (
                            <div onClick={() => onClickView(notification)} key={index} className={`${notification.read_at ? 'read' : 'unread'} px-3 cursor-pointer`}>
                                <div className='pt-3 pb-2 flex justify-content-between'>
                                    <div className='flex flex-column'>
                                        {notification?.data?.message === "New Status Added" ? (
                                            <Link to={StatusRoute} className='no-underline text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</Link>
                                        ) : notification?.data?.message === "New Exception Added" ? (
                                            <Link to={ExceptionRoute} className='no-underline text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</Link>
                                        ) : (
                                            <span className='notif-message text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</span>
                                        )}    
                                          <span className='notif-time text-gray-500 text-sm font-normal'>{getTime(notification?.created_at)}</span>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        )
                    })
                    :
                    <div className={`read px-3`}>
                        <div className='pt-3 pb-2 flex justify-content-center'>
                            <span className='text-gray-500 text-base font-normal'>
                                No New Notifications
                            </span>
                        </div>
                    </div>
            }
        </div>
    </TabPanel>

</TabView>
</div>
    </div>
  )
}

export default ViewAllNotification
