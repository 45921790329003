import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Link, useNavigate } from 'react-router-dom';
import httpClient from './api';
import useUserStore from '../../store/UserStore';


export default function NotificationOverlay({ notifications, unread_notifications,onrefresh,onclose, ...props }) {
    const navigate=useNavigate();
    const { setMarkAsReadNotification } = useUserStore();
    const StatusRoute = `${process.env.REACT_APP_API_URL}/admin/status`;
    const ExceptionRoute = `${process.env.REACT_APP_API_URL}/admin/exceptions`;
    const getTime = (date) => {
        const specificDate = new Date(date);
        const currentDate = new Date();
    
        const timeDifferenceInMilliseconds = currentDate - specificDate;
    
        const minutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
        const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
        const days = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));
        const weeks = Math.floor(days / 7); 
    
        const specificYear = specificDate.getFullYear();
        const currentYear = currentDate.getFullYear();
        const specificMonth = specificDate.getMonth();
        const currentMonth = currentDate.getMonth();
    
        const months = (currentYear - specificYear) * 12 + (currentMonth - specificMonth);
        const years = Math.floor(months / 12);
    
        if (minutes < 60) {
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
        } else if (hours < 24) {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
        } else if (days < 7) {
            return `${days} ${days === 1 ? 'day' : 'days'} ago`;
        } else if (weeks < 4) {
            return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
        } else if (months < 12) {
            return `${months} ${months === 1 ? 'month' : 'months'} ago`;
        } else {
            return `${years} ${years === 1 ? 'year' : 'years'} ago`;
        }
    };

    const onClickView = async(notification) => {
        if(notification?.data?.message==="Download File - Link"){
            const url = notification.data.url;
            const link = document.createElement('a');
            link.href = url;
            link.download = ''; 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        
            try {
              const response = await httpClient.get(`/api/mark-as-read/${notification?.id}`);
              if(response.status===200){
                onrefresh();
                setMarkAsReadNotification(true); 
              }
            } catch (error) {
              console.log(error);
            }
         
    }
   
    const onClickViewAll = () => {
        navigate("/viewall");
        onclose();
    }

    const onClickMarkAsRead = async() => {
       if(notifications?.length && unread_notifications?.length) {
        try {
            const response = await httpClient.get(`/api/mark-as-read`);
            if(response.status===200){
                onrefresh();
            }
          } catch (error) {
            console.log(error);
          }
       }
    }

    const templateHeader = (
        <div className='notification-header text-lg font-medium'>
            <span>Notifications</span>
        </div>
    );
    const templateFooter = (
        <div onClick={() => onClickViewAll()} className='underline cursor-pointer text-black-alpha-90 text-sm text-center pt-2'>View All</div >
    );
    return (
        <div className='notification pb-3 relative'>
            {templateHeader}
            <span onClick={() => onClickMarkAsRead()} className={`mark-as-read underline text-black-alpha-90 text-sm select-none ${notifications?.length && unread_notifications?.length ? 'cursor-pointer' : 'cursor-default opacity-50'}`}>Mark all as read</span >

            <TabView className='notification-tabview'>
                <TabPanel header="All">
                    <div className='notification-body overflow-y-scroll'>
                        {
                            notifications?.length ?
                                notifications?.map((notification, index) => {
                                    return (
                                        <div onClick={() => onClickView(notification)} key={index} className={`${notification.read_at ? 'read' : 'unread'} px-3 cursor-pointer`}>
                                            <div className='pt-3 pb-2 flex justify-content-between'>
                                                <div className='flex flex-column'>
                                                {notification?.data?.message === "New Status Added" ? (
                                            <Link to={StatusRoute} className='no-underline text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</Link>
                                        ) : notification?.data?.message === "New Exception Added" ? (
                                            <Link to={ExceptionRoute} className='no-underline text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</Link>
                                        ) : (
                                            <span className='text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</span>
                                        )}
                                        <span className='text-gray-500 text-sm font-normal'>{getTime(notification?.created_at)}</span>       </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })
                                :
                                <div className={`read px-3`}>
                                    <div className='pt-3 pb-2 flex justify-content-center'>
                                        <span className='text-gray-500 text-base font-normal'>
                                            No Notifications
                                        </span>
                                    </div>
                                </div>
                        }
                    </div>
                </TabPanel>
                <TabPanel header="Unread">
                    <div className='notification-body overflow-y-scroll'>

                        {
                            
                            unread_notifications?.length ?
                                unread_notifications?.map((notification, index) => {
                                    return (
                                        <div onClick={() => onClickView(notification)} key={index} className={`${notification.read_at ? 'read' : 'unread'} px-3 cursor-pointer`}>
                                            <div className='pt-3 pb-2 flex justify-content-between'>
                                                <div className='flex flex-column'>
                                                {notification?.data?.message === "New Status Added" ? (
                                            <Link to={StatusRoute} className='no-underline text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</Link>
                                        ) : notification?.data?.message === "New Exception Added" ? (
                                            <Link to={ExceptionRoute} className='no-underline text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</Link>
                                        ) : (
                                            <span className='text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</span>
                                        )}    <span className='text-gray-500 text-sm font-normal'>{getTime(notification?.created_at)}</span>    </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })
                                :
                                <div className={`read px-3`}>
                                    <div className='pt-3 pb-2 flex justify-content-center'>
                                        <span className='text-gray-500 text-base font-normal'>
                                            No New Notifications
                                        </span>
                                    </div>
                                </div>
                        }
                    </div>
                </TabPanel>

            </TabView>
            {templateFooter}
        </div>
    );
}
