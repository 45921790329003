import React from "react";
import { TabView, TabPanel } from "primereact/tabview";

export default function NotificationOverlay() {
  const notifications = [
    {
      notification: "Find out what change in Ceva Logistics",
      view: "View",
    },
    {
      notification: "Browse Complete Documentation",
      view: "View",
    },
    {
      notification: "Contact Support",
      view: "View",
    },
  ];
  const getTime = (date) => {
    const specificDate = new Date(date);
    const currentDate = new Date();

    const timeDifferenceInMilliseconds = currentDate - specificDate;

    const minutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
    const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
    const days = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(days / 7); 

    const specificYear = specificDate.getFullYear();
    const currentYear = currentDate.getFullYear();
    const specificMonth = specificDate.getMonth();
    const currentMonth = currentDate.getMonth();

    const months = (currentYear - specificYear) * 12 + (currentMonth - specificMonth);
    const years = Math.floor(months / 12);

    if (minutes < 60) {
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (hours < 24) {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else if (days < 7) {
        return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    } else if (weeks < 4) {
        return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
    } else if (months < 12) {
        return `${months} ${months === 1 ? 'month' : 'months'} ago`;
    } else {
        return `${years} ${years === 1 ? 'year' : 'years'} ago`;
    }
};

  // const onClickView = (notification) => {
  //     get(route('mark-as-read', { id: notification?.id }))
  // }

  // const onClickViewAll = () => {
  //     get(route('notifications'))
  // }

  const templateHeader = (
    <div className="notification-header ">
      <span>Help</span>
    </div>
  );

  return (
    <div className="notification pb-3 relative">
      {templateHeader}
      <div className="notification-body ">
        {notifications?.length ? (
          notifications?.map((notification, index) => {
            return (
              <div key={index} className="mt-1">
                <div className="pt-3 pb-2 px-3">
                  <div className="flex justify-content-between  align-items-center ">
                    <div className="notif-message ">
                      {" "}
                      {index + 1}.
                      <span >
                        {notification?.notification}
                      </span>
                    </div>{" "}
                    <span className="notif-time underline cursor-pointer" onClick={()=>console.log(index)}>
                      {notification?.view}
                    </span>
                  </div>
                </div>

                <hr />
              </div>
            );
          })
        ) : (
          <div className={`read px-3`}></div>
        )}
      </div>
    </div>
  );
}
